import { defineComponent } from 'vue'
import { BDropdown } from 'bootstrap-vue'

declare global {
    interface Window {
        authUrl: string
    }
}

export default defineComponent({
    computed: {
        iframeElement(): HTMLElement {
            return this.$refs['create-menu-iframe'] as HTMLElement
        },
        dropdownElement(): BDropdown {
            return this.$refs['create-menu'] as BDropdown
        },
    },
    mounted() {
        this.createIframe()
    },
    methods: {
        createIframe() {
            const iframe = document.createElement('iframe')
            iframe.id = 'iframe-menu'
            iframe.src = `${window.authUrl}/create-menu`
            iframe.style.border = 'none'
            iframe.style.width = '630px'
            iframe.style.height = '350px'
            iframe.style.display = 'block'
            iframe.name = location.host.split('.')[0]
            this.iframeElement?.appendChild(iframe)
        },
        handleCreateButtonHover(hovered: boolean) {
            if (this.dropdownElement) {
                hovered ? this.dropdownElement.show() : this.dropdownElement.hide()
            }
        },
    },
})
